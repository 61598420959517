angular.module 'app'
.controller 'FansUpdateCtrl', ($scope, $http, $state, $uibModalInstance, config, Storage, FileUploader, growl, data, dialogs) ->
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    original_id: null
    token: null
    cookie: null
    openid: null
    limit: null
    offset: null
    isDisableSave: false

  $scope.cancel = ->
    $uibModalInstance.dismiss 'Canceled'

  $scope.save = ->
    if !$scope.ui.original_id or $scope.ui.original_id.length == 0
      return growl.error('請輸入公眾號原始ID')
    if !$scope.ui.token or $scope.ui.token.length == 0
      return growl.error('請輸入token信息')
    if !$scope.ui.cookie or $scope.ui.cookie.length == 0
      return growl.error('請輸入cookie信息')
    $scope.ui.isDisableSave = true

    data_ticket = $scope.ui.cookie.match(/data_ticket=([^;]+)/)
    data_bizuin = $scope.ui.cookie.match(/data_bizuin=([^;]+)/)
    slave_sid = $scope.ui.cookie.match(/slave_sid=([^;]+)/)

    if !data_ticket or !data_bizuin or !slave_sid
      return growl.error('cookie error!')

    _data =
      original_id: $scope.ui.original_id
      token: $scope.ui.token
      data_ticket: data_ticket[1]
      data_bizuin: data_bizuin[1]
      slave_sid: slave_sid[1]
    _data.openid = $scope.ui.openid if $scope.ui.openid
    _data.limit = Number($scope.ui.limit) if $scope.ui.limit
    _data.offset = Number($scope.ui.offset) if $scope.ui.offset
    $http.post "#{config.api.baseUrl}/collect/fans?access_token=" + Storage.get('access_token'), _data
    .then (res) ->
      $uibModalInstance.close 'Save'
      growl.success "提交成功！"
      $scope.ui.isDisableSave = false
    .catch (error) ->
      growl.error error.data.error
      # if error.data.error.indexOf('errtext') >= 0
      #   err = JSON.parse error.data.error
      #   growl.error err.errtext
      # else
      #   growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisableSave = false